"use strict";

$(function () {
    if (enableSinglePageApp) {
        if (localStorage.getItem("prefersSpa") == "true") {
            location.href = '../spa';
        }

        $(".changeSite").on("click", () => {
            localStorage.setItem("prefersSpa", "true");
        });
    }

    $(".input-validation-error")
        .next(".field-validation-error")
        .addClass("glyphicon glyphicon-remove form-control-feedback")
        .attr("title", function() {
            return $(this).html();
        })
        .empty()
        .closest(".form-group")
        .addClass("has-error has-feedback");
});
